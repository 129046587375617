.wrapper {
    height: 56px;
    padding: 0 1rem;
    background: var(--color-gray-50);
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 1.5rem;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-400);
}

.logoWrapper {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 42px;
}

.logoWrapper img {
    height: 40px;
}

.navigation {
    display: none;
    flex: 1;
    flex-direction: row;
    gap: 1rem;
}

.navigationItem {
    display: grid;
    height: 100%;
    justify-content: center;
    align-content: center;
    padding: 0 .5rem;
    position: relative;
    text-decoration: none;
    color: var(--color-gray-1000);
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabled {
    color: var(--color-gray-700);
}

.spanGrid {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: center;
    align-content: center;
}

.navigationItem img {
    display: inline;
}

.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 6px;
    background: var(--color-orange-500);
}

.login {
    display: none;
    justify-content: center;
    align-content: center;
}

.hamburgerWrapper {
    display: grid;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

@media screen and (min-width: 48rem) {
    .wrapper {
        height: 80px;
        border-bottom: unset;
    }

    .logoWrapper {
        min-width: 66px;
    }

    .logoWrapper img {
        height: 64px;
    }

    .navigation {
        display: flex;
    }

    .login {
        display: flex;
        min-width: fit-content;
        gap: 1rem;
        align-items: center;
    }

    .hamburgerWrapper {
        display: none;
    }
}

@media screen and (min-width: 60rem) {
    .wrapper {
        padding: 0 5rem;
    }
}
