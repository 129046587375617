.userWrapper {
    height: 40px;
    position: relative;
    display: flex;
    width: calc(100% + 1rem);
    transform: translateX(-0.5rem);
    gap: 1rem;
}

.userWrapper img {
    border-radius: 50px;
}

.logout {
    flex: 1;
    height: 48px;
    display: flex;
    background: var(--color-orange-500);
    text-align: center;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: .5rem;
    cursor: pointer;
    font-weight: 600;
}

.logout span {
    font-size: 0.875rem;
    display: grid;
    justify-content: center;
    align-content: center;
    line-height: 1;
}

.imageWrapper {
    display: grid;
    justify-content: center;
    align-content: center;
}

.imageWrapper img {
    width: 16px;
    height: 16px;
}
