.wrapper {
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
}

.titleWrapper {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
}

.titleWrapper p {
    text-align: center;
    font-weight: 600;
    color: var(--color-gray-700);
}


@media screen and (min-width: 48rem) {
    .titleWrapper {
        gap: 2.5rem;
    }
}
