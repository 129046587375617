.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (min-width: 48rem) {
    .wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
