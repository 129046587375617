.dialog {
    --outside-margin: 1rem;
    position: fixed;
    margin: 0;
    width: calc(100vw - var(--outside-margin) * 2);
    height: calc(100vh - var(--outside-margin) * 2);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-300);
    border: 1px solid var(--color-gray-500);
    border-radius: .75rem;
    outline: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0A0A0BB2;
}

.header {
    display: flex;
    flex-direction: row;
    height: 56px;
    padding: 0 1rem;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-500);
    font-weight: 600;
    color: var(--color-gray-1000);
}

.title {
    flex: 1;
}

.close {
    cursor: pointer;
}

.content {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    border-bottom: 1px solid var(--color-gray-500);
    font-size: 0.875rem;
    color: var(--color-gray-800);
}

.footer {
    display: flex;
    flex-direction: row;
    height: 56px;
    padding: 0 1rem;
    align-items: center;
    justify-content: flex-end;
}

.cta {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-orange-500);
    border-radius: 8px;
    color: var(--color-gray-1000);
    font-weight: 600;
    text-decoration: none;
    height: 40px;
    cursor: pointer;
    padding: 0 1rem;
}

.cta:hover {
    background-color: var(--color-orange-700);
}

@media screen and (min-width: 41rem) {
    .dialog {
        width: min(calc(100vw - var(--outside-margin) * 2), 640px);
        height: min(calc(100vh - var(--outside-margin) * 2), 600px);
    }
}
