.footer {
    background-color: var(--color-gray-300);
    padding: 2rem 0 1.25rem;
}

.wrapper {
    display: grid;
    grid-template-areas:
            "text"
            "socials"
            "links"
            "divider"
;
    /*grid-template-rows: 1fr 1fr 1fr;*/
    /*grid-template-columns: 1fr;*/
    gap: 1rem;

}

.text {
    grid-area: text;
    display: flex;
    gap: 1.5rem;
    flex-direction: row;
    color: var(--color-gray-700);
    font-weight: 500;
    font-size: .75rem;
}

.text p {
    flex: 1;
    max-width: 60ch;
}

.links {
    grid-area: links;
    display: flex;
    justify-content: space-between;
}

.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sectionLinks {
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.link {
    font-size: .75rem;
    color: var(--color-gray-700);
    text-decoration: none;
}

.socials {
    grid-area: socials;
    display: flex;
}

.imageWrapper {
    width: 80px;
}

.socialIcon img {
    width: 132px;
    height: 64px;
}

.dividerWrapper {
    grid-area: divider;
}

.divider {
    height: 1px;
    width: 100%;
    background: var(--color-gray-500);
}

.poweredBy {
    width: 100%;
    display: block;
    text-align: center;
    font-size: .75rem;
    color: var(--color-gray-700);
    margin-top: 1rem;
}

@media screen and (min-width: 48rem) {
    .wrapper {
        grid-template-rows: 1fr auto auto;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "text links"
            "socials socials"
            "divider divider"
        ;
        gap: 1.5rem;
    }

    .text {
        flex-direction: column;
        font-size: .875rem;
    }

    .link {
        font-size: .875rem;
    }

    .imageWrapper {
        width: 125px;
        flex: 1;
    }

    .socialIcon img {
        width: 165px;
        height: 80px;
    }

    .poweredBy {
        font-size: .875rem;
    }
}
