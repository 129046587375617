:root {
    --color-gray-50: #0A0A0B;
    --color-gray-100: #0F1011;
    --color-gray-200: #131517;
    --color-gray-300: #1B1D22;
    --color-gray-400: #282B31;
    --color-gray-500: #393C3F;
    --color-gray-700: #808390;
    --color-gray-800: #B9BAD0;
    --color-gray-900: #D3D3D3;
    --color-gray-1000: #FAFAFA;

    --color-orange-500: #E45D11;
    --color-orange-700: #E99911;
    --color-orange-800: #EAA610;

    --color-shadow: #63220340;

    --color-link: #2C8FED;
    /*--color-link: #42A2FB;*/

    --color-gradient-start: #EAA610;
    --color-gradient-end: #E34B11;

    --font-inter: 'Inter', sans-serif;
    --font-teko: 'Teko', sans-serif;
    --font-farro: 'Farro', sans-serif;
}

body {
    color: var(--color-gray-1000);
    background-color: var(--color-gray-50);
    font-family: var(--font-inter);
    /*font-size: 1.25rem;*/
}

.app {
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    position: relative;
}

/* utility classes */
.scrollBlock {
    overflow: hidden;
}

.container {
    --max-width: 1240px;
    --container-padding: 1.5rem;

    width: min(var(--max-width), 100% - (var(--container-padding) * 2));
    margin-inline: auto;
}

.mainContent {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.flexGrow {
    flex: 1;
}

/* headers */
h1 {
    font-size: 4rem;
    font-weight: 700;
    font-family: var(--font-teko);
    text-transform: uppercase;
    line-height: 1;
    text-shadow: 3px 3px 1px rgba(99,34,3,0.25);
    letter-spacing: 2px;
}

h2 {
    font-size: 3rem;
    font-weight: 700;
    font-family: var(--font-teko);
    text-transform: uppercase;
    line-height: 1;
    color: var(--color-gray-1000);
}

h3 {
    font-size: 1.125rem;
    font-weight: 700;
    font-family: var(--font-teko);
    color: var(--color-gray-1000);
    line-height: 1;
    text-transform: uppercase;
}

h4 {
    font-size: 2.25rem;
    font-weight: 700;
    font-family: var(--font-teko);
    color: var(--color-gray-1000);
    line-height: 1;
    text-transform: uppercase;
}

h5 {
    font-size: 1.375rem;
    font-weight: 700;
    font-family: var(--font-teko);
    color: var(--color-gray-1000);
    line-height: 1;
    text-transform: uppercase;
}

h6 {
    font-size: 1.4375rem;
    font-weight: 700;
    font-family: var(--font-teko);
    color: var(--color-gray-1000);
    line-height: 1;
    text-transform: uppercase;
}

.heroSubTitle {
    font-size: 1.25rem;
    font-family: var(--font-inter);
    line-height: 1.25;
    font-weight: 600;
    text-shadow: 2px 2px 1px rgba(99,34,3,0.25);
    max-width: 40ch;
}

.heroSubTitleHomepage {
    font-size: 1.5rem;
}

/* event info */
.bold {
    font-weight: bold;
}

.regular-link {
    color: var(--color-link);
    text-decoration: none;
    font-weight: bold;
}

.cta {
    color: var(--color-gray-1000);
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    font-size: .875rem;
    width: fit-content;
    padding: .75rem 1.25rem;
    border-radius: 8px;
    background-color: var(--color-orange-500);
    cursor: pointer;
}

.cta-secondary {
    background-color: transparent;
    border: 1px solid var(--color-orange-500);
    cursor: pointer;
}


@media screen and (min-width: 48rem) {
    .mainContent {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
    }

    h1 {
        font-size: 5rem;
        white-space: nowrap;
        letter-spacing: 4px;
    }

    h2 {
        font-size: 4rem;
    }

    h3 {
        font-size: 1.375rem;
    }

    h4 {
        font-size: 3rem;
    }
}


@media screen and (min-width: 52rem) {
    h1 {
        font-size: 5.5rem;
    }
}

@media screen and (min-width: 60rem) {
    h1 {
        font-size: 6.5rem;
    }
}

@media screen and (min-width: 70rem) {
    h1 {
        font-size: 7.5rem;
    }
}

@media screen and (min-width: 75rem) {
    h1 {
        font-size: 8rem;
    }
}
