.wrapper {
    height: 560px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.imageWrapper img {
    max-width: unset;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
}

.imageWrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 25, 34, 0.75);
    z-index: 1;
}

.textWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .75rem;
}

.title {
    font-size: 2.25rem;
    text-align: center;
}

@media screen and (min-width: 41.25rem) {
    .title {
        font-size: 4rem;
    }
}

@media screen and (min-width: 90rem) {
    .title {
        font-size: 8rem;
    }
}