.wrapper {
    background-image: radial-gradient(ellipse closest-side, var(--color-gradient-start) 0%, var(--color-gradient-end) 150%);
    display: grid;
    justify-content: center;
    align-content: center;
    min-height: 380px;
}

.textWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

@media screen and (min-width: 48rem) {

    .wrapper {
        min-height: 360px;
    }

    .textWrapper {
        gap: .5rem;
    }
}
