.wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: .75rem;
}

@media screen and (min-width: 48rem) {
    .wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
}


@media screen and (min-width: 64rem) {
    .wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}
