.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
}

.titleWrapper {
    display: flex;
    gap: .5rem;
    flex-direction: column;
}

.wrapper h2 {
    font-size: 1.625rem;
}

.tabs {
    display: flex;
    flex-direction: row;
    background-color: var(--color-gray-300);
    gap: 1rem;
    justify-content: flex-start;
    border-bottom: 2px solid var(--color-gray-500);
}

.tab {
    position: relative;
    color: var(--color-gray-1000);
    font-size: .875rem;
    text-align: center;
    padding: 0 .75rem;
    font-weight: 600;
    min-height: 40px;
    max-height: 40px;
    width: fit-content;
    display: grid;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    flex-shrink: 0;
}

.active::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-bottom: 5px solid var(--color-orange-500);
}

.contentWrapper {
    background-color: var(--color-gray-300);
    color: var(--color-gray-900);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: fit-content;
    overflow: hidden;
}




@media screen and (min-width: 41rem) {
    .wrapper h2 {
        font-size: 2.25rem;
    }
}


@media screen and (min-width: 75rem) {
    .wrapper {
        width: 100%;
    }

    .contentWrapper {
        flex: 1;
    }
}
