.wrapper {
    border: 1px solid var(--color-gray-500);
    background-color: var(--color-gray-300);
    border-radius: 14px;
    padding: 1.25rem 1.25rem 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
    isolation: isolate;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    align-items: center;
}

.textWrapper span {
    font-size: .875rem;
    font-weight: 500;
    color: var(--color-orange-800);
}

.wrapper p {
    font-size: .875rem;
    font-weight: 600;
    color: var(--color-gray-800);
    width: 100%;
    text-align: center;
    position: relative;
}

.wrapper p::before {
    content: '“';
    width: fit-content;
    color: var(--color-gray-400);
    font-family: var(--font-farro);
    font-size: 7.5rem;
    position: absolute;
    top: -2.5rem;
    left: 0;
    z-index: -1;
}

.wrapper p::after {
    content: '”';
    width: fit-content;
    color: var(--color-gray-400);
    font-family: var(--font-farro);
    font-size: 7.5rem;
    position: absolute;
    top: -1.5rem;
    right: 0;
    z-index: -1;
}



/*content: '“';*/
/*width: fit-content;*/
/*!* height: fit-content; *!*/
/*!* height: 40px; *!*/
/*color: var(--color-gray-400);*/
/*font-family: var(--font-farro);*/
/*font-size: 7.5rem;*/
/*position: absolute;*/
/*left: 0;*/
/*z-index: -1;*/
/*border: 1px solid red;*/
/*!* display: grid; *!*/
/*!* padding: 0; *!*/
/*!* margin: 0; *!*/
/*!* transform: translateY(-50%); *!*/
/*!* align-content: center; *!*/
/*top: -2.5rem;*/



