.wrapper {
    background-color: var(--color-gray-300);
    border: 1px solid var(--color-gray-500);
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.tabsWrapper {
    width: 100%;
    border-bottom: 1px solid var(--color-gray-500);
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.tab {
    padding: 4px;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
}

.tab:hover {
    cursor: pointer;
}

.tabActive::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: var(--color-orange-500);
}

.overview {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: var(--color-gray-800);
}

.overviewItem span,
.overviewItem b{
    font-weight: 600;
    color: var(--color-gray-1000);
}

.submissionList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.submissionSection {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.submissionSection::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--color-gray-500);
    border-radius: 10px;
}


.submissionSection:last-child::after {
    display: none;
}


.submissions {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.submission {
    display: flex;
    flex-direction: column;
    border: 2px solid var(--color-gray-500);
    border-radius: 14px;
    overflow: hidden;
}

.imageWrapper {
    width: 100%;
    max-height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-50);
    border-bottom: 1px solid var(--color-gray-500);
}

.noImage {
    padding: 1.5rem;
}

.image {
    max-width: unset;
    width: 100%;
    max-height: 220px;
    object-fit: cover;
}

.imageSmall {
    width: 200px;
    height: 200px;
}

.submissionContentWrapper {
    border-top: 1px solid var(--color-gray-500);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: .875rem;
    color: var(--color-gray-800);
}

.submissionSubtitle {
    color: var(--color-orange-800);
}

.submissionContent {
    flex: 1;
}

.membersTitle {
    color: var(--color-gray-1000);
}

.gallery {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.galleryText {
    font-size: .875rem;
    color: var(--color-gray-800);
}

.gallerySpan {
    display: block;
    color: var(--color-gray-1000);
    font-weight: 600;
}

.galleryText a,
.galleryText a:hover,
.galleryText a:visited {
    color: #2C8FED;
}

@media screen and (min-width: 41.25rem) {
    .title {
        font-size: 2.25rem;
    }
}

@media screen and (min-width: 75rem) {
    .title {
        font-size: 2.25rem;
    }

    .submission {
        flex-direction: row;
    }

    .imageWrapper {
        width: 360px;
        max-width: 360px;
        min-width: 360px;
        border-right: 1px solid var(--color-gray-500);
        border-bottom: unset;
        max-height: unset;
    }

    .image {
        max-height: unset;
    }

    .submissionContentWrapper {
        border-left: 1px solid var(--color-gray-500);
        border-top: unset;
    }
}