.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bold {
    font-weight: bold;
}

.submissionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
}

.submissions {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
