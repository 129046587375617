.wrapper {
    border: 1px solid var(--color-gray-500);
    background-color: var(--color-gray-300);
    border-radius: 14px;
    padding: 1rem 1.25rem 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
    isolation: isolate;
}

.unlockable {
    cursor: pointer;
    border-color: var(--color-orange-700);
}

.blur {
    animation: blur 3s ease 0s;
    -webkit-animation: blur 3s ease;
    -moz-animation: blur 3s ease;
}

@keyframes blur {
    0%,
    100% {
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
    }
    50% {
        -webkit-filter: blur(50px);
        -moz-filter: blur(50px);
        -o-filter: blur(50px);
        -ms-filter: blur(50px);
    }
}

.textWrapper {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    align-items: center;
}

.textWrapper span {
    font-size: .875rem;
    font-weight: 500;
    color: var(--color-orange-800);
}

.wrapper p {
    font-size: .875rem;
    font-weight: 600;
    color: var(--color-gray-800);
    width: 100%;
    text-align: center;
    position: relative;
}

@media screen and (min-width: 48rem) {
    .wrapper {
        max-width: 235px;
        min-width: 235px;
    }
}
