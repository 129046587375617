.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 56px);
    position: relative;
}

.imageWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.imageWrapper img {
    max-width: unset;
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.imageWrapper::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 45px;
    background-image: linear-gradient(90deg, rgba(225, 50, 17, 1), rgba(234, 171, 15, 1));
}

.imageDesktop {
    display: none;
}

.textWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    z-index: 1;
}

.logo {
    width: 320px;
}

.title {
    font-size: 2.25rem;
}

.cta {
    display: grid;
    justify-content: center;
    align-content: center;
    color: white;
    background-color: #E45D11;
    border-radius: 8px;
    height: 40px;
    width: 115px;
    text-decoration: none;
    margin-inline: auto;
}

@media screen and (min-width: 48rem) {

    .wrapper {
        min-height: calc(100vh - 80px);
    }

    .imageWrapper::before {
        width: 1px;
        height: 100%;
        left: 45px;
        bottom: 0;
    }

    .imageMobile {
        display: none;
    }

    .imageDesktop {
        display: block;
    }


    .textWrapper {
        text-align: left;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 0 clamp(100px, 8vw, 200px);
        /*max-width: 1400px;*/
        /*gap: 5rem;*/
    }

    .logo {
        flex: 1;
        max-width: 626px;
    }

    .title {
        flex: 1;
        display: flex;
        font-size: clamp(4rem, 8vw, 8rem);
    }


    .cta {
        margin: 0;
    }
}
