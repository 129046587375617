.wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
}

.questionWrapper {
    background-color: var(--color-gray-400);
    display: flex;
    flex-direction: column;
}

.question {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 1rem 1.25rem;
    background-color: var(--color-gray-300);
    border-bottom: 1px solid var(--color-gray-500);
    align-items: center;
    justify-content: space-between;
}

.questionWrapper:last-child .question{
    border-bottom: unset;
}

.questionText {
    flex: 1;
    font-size: 1rem;
    font-weight: 600;
}

.answer {
    background-color: var(--color-gray-100);
    padding: 1.5rem 1.25rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.answer a,
.answer a:active,
.answer a:visited {
    color: var(--color-link);
    text-decoration: none;
}

@media screen and (min-width: 48rem) {
    .wrapper {
        flex: 1;
        height: fit-content;
    }
}

