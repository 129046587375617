.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.tabs {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    background-color: var(--color-gray-300);
    border-radius: 12px;
    padding: .5rem;
    gap: .25rem;
    justify-content: space-between;
}

.tab {
    color: var(--color-gray-1000);
    font-size: .875rem;
    text-align: center;
    padding: 0 .75rem;
    font-weight: 600;
    border-radius: 4px;
    min-height: 28px;
    max-height: 28px;
    width: fit-content;
    display: grid;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    flex-shrink: 0;
}

.active {
    background-color: var(--color-orange-500);
}

.contentWrapper {
    background-color: var(--color-gray-300);
    border: 1px solid var(--color-gray-500);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    gap: 1rem;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


@media screen and (min-width: 48rem) {
    .wrapper {
        flex-direction: row;
        width: 100%;
    }

    .tabs {
        width: 292px;
        max-width: 292px;
        overflow: hidden;
        flex-direction: column;
        justify-content: flex-start;
        height: fit-content;
        padding: 1rem .75rem;
        gap: .5rem;
    }

    .tab {
        width: 100%;
        justify-content: flex-start;
        border-radius: 8px;
        padding: .75rem 1.25rem;
        border: 1px solid var(--color-gray-500);
        min-height: 48px;
        max-height: 48px;
        font-weight: 500;
    }

    .active {
        background-color: inherit;
        border: 1px solid var(--color-orange-500);
    }

    .contentWrapper {
        flex: 1;
    }
}
