.wrapper {
    z-index: 9999;
    height: 100vh;
    background: var(--color-gray-200);
    position: absolute;
    width: 100%;
    top: 56px;
    padding: 1.5rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.hidden {
    display: none;
}

.navigation {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}

.navigationItem {
    display: flex;
    width: 100%;
    height: 3.5rem;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: var(--color-gray-1000);
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
}

.disabled {
    color: var(--color-gray-700);
}

.spanGrid {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: center;
    align-content: center;
}

.navigationItem img {
    display: inline;
}

.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% + 1rem);
    transform: translateX(-.5rem);
    height: 3px;
    background: var(--color-orange-500);
}

.divider {
    height: 1px;
    width: calc(100% + 1rem);
    transform: translateX(-.5rem);
    background: var(--color-gray-500);
}

@media screen and (min-width: 48rem) {
    .wrapper {
        display: none;
    }
}

