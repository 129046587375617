.wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.title {
    font-size: 1.75rem;
}

.cards {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.thanks {
    display: flex;
    flex-direction: column;
    gap: 1em;
    color: var(--color-gray-800);
    font-size: 0.875rem;
}

.bold {
    font-weight: 600;
    color: white;
}

@media screen and (min-width: 41rem) {
    .title {
        font-size: 2.25rem;
    }
}

@media screen and (min-width: 41rem) {
    .title {
        font-size: 1.75rem;
    }
}
