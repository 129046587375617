.wrapper {
    display: flex;
    flex-direction: column;
    border: 2px solid var(--color-gray-500);
    border-radius: 15px;
    overflow: hidden;
}

.imageWrapper {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-500);
    background-color: var(--color-gray-50);
}

.contentWrapper {
    padding: 1rem;
    border-top: 1px solid var(--color-gray-500);
    background-color: var(--color-gray-300);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: .875rem;
    color: var(--color-gray-800);
}

.titleWrapper {
    display: flex;
    flex-direction: column;
}

.subtitle {
    color: var(--color-gray-700);
}

.submitter {
    color: var(--color-gray-1000);
    font-weight: 600;
}

.cta {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-orange-500);
    border-radius: 8px;
    color: var(--color-gray-1000);
    font-weight: 600;
    text-decoration: none;
    height: 40px;
    cursor: pointer;
}

.cta:hover {
    background-color: var(--color-orange-700);
}

@media screen and (min-width: 41.25rem) {
    .cta {
        padding: 0 11px;
        width: fit-content;
    }
}

@media screen and (min-width: 75rem) {
    .wrapper {
        flex-direction: row;
    }

    .imageWrapper {
        border-bottom: none;
        border-right: 1px solid var(--color-gray-500);
    }

    .contentWrapper {
        flex: 1;
        border-top: none;
        border-left: 1px solid var(--color-gray-500);
    }

    .content {
        flex: 1;
    }
}

