.wrapper {
    background-image: radial-gradient(ellipse closest-side, var(--color-gradient-start) 0%, var(--color-gradient-end) 150%);
    display: grid;
    justify-content: center;
    align-content: center;
    min-height: 380px;
    padding: 1rem;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imageWrapper {
    display: grid;
    justify-content: center;
    align-content: center;
}

.imageWrapper img {
    min-width: 154px;
    min-height: 148px;
}

.textWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

@media screen and (min-width: 48rem) {

    .contentWrapper {
        flex-direction: row-reverse;
    }

    .imageWrapper img {
        min-width: 250px;
        min-height: 240px;
    }

    .title {
        width: 100%;
        font-size: 4rem;
        text-align: left;
    }

    .subTitle {
        font-size: 1rem;
        text-align: left;
        max-width: unset;
    }

    .wrapper {
        min-height: 360px;
    }

    .textWrapper {
        gap: .5rem;
        flex: 1;
    }
}
