.wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    background-color: #1b1d22;
    border-radius: 1rem;
    padding: 1rem;
}

.title {
    cursor: pointer;
    position: relative;
}

.title h3 {
    font-size: 2rem;
    max-width: 70%;
}

.label {
    --background-color: #2A6FBF;

    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    background-color: var(--background-color);
    font-weight: 700;
    padding: .25rem .75rem;
    font-size: .75rem;
    border-radius: 10rem;
}

.tools {
    --background-color: #C97B16;
}

.office {
    --background-color: #358E76;
}

.website {
    --background-color: #9C1B25;
}

.processes {
    --background-color: #6B2D8C;
}

.titleInfo {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.author {
    font-style: italic;
}

.idea {
    position: relative;
    margin-top: 1rem;
    padding: .5rem .5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.idea p {
    margin-bottom: 1rem;
}

.coollabTitle {
    font-size: 1.75rem;
}

.cta {
    display: block;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    padding: .25rem .75rem;
    text-decoration: none;
    background-color: darkorange;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    text-align: center;
}

.cta:hover {
    background-color: #e34b11;
}
