.wrapper {
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    align-items: center;
}

.sectionTitle p {
    text-align: center;
    max-width: 60ch;
    font-size: .875rem;
    color: var(--color-gray-700);
    font-weight: 600;
}

@media screen and (min-width: 48rem) {
    .section {
        gap: 1.5rem;
    }

    .sectionTitle {
        align-items: flex-start;
    }

    .sectionTitle p {
        text-align: left;
        max-width: 120ch;
    }

    .row {
        flex-direction: row;
    }
}
