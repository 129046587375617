.wrapper {
    display: flex;
    flex-direction: row;
    color: var(--color-gray-1000);
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
    position: relative;
    width: calc(100% + 1rem);
    transform: translateX(-0.5rem);
    height: 48px;
    gap: .5rem;
    border: 1px solid var(--color-orange-500);
    border-radius: 8px;
    font-weight: 600;
}

.wrapper:hover {
    border-color: var(--color-orange-700);
}

.text {
    display: inline-block;

}

@media screen and (min-width: 48rem) {
    .wrapper {
        height: 40px;
        padding: 1rem;
        width: fit-content;
        transform: unset;
    }


}