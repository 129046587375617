.outerWrapper {
    background: linear-gradient(93.51deg, #FABA16 -0.82%, #EAA610 11.9%, #E99310 23.02%, #E77E10 40.51%, #E34B11 100.9%);
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1.5rem 2rem;
    align-items: center;
}

.logoWrapper {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100px;
}

.logoWrapper img {
    height: 100px;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1rem
}

.textWrapper p {
    font-family: var(--font-inter);
    line-height: 1.25;
    font-weight: 600;
    text-shadow: 2px 2px 1px rgba(99,34,3,0.25);
}

.title {
    font-size: 1.875rem;
    letter-spacing: 2px;
}

@media screen and (min-width: 40rem) {
    .wrapper {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .logoWrapper {
        display: grid;
        justify-content: center;
        align-content: center;
        width: 150px;
    }

    .logoWrapper img {
        height: 150px;
    }

    .textWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        gap: 1rem
    }
}
